import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faQuestionCircle } from "@fortawesome/pro-light-svg-icons"
import { Link } from "@reach/router"
import React, { FunctionComponent, useCallback, useEffect } from "react"
import { Helmet as Head } from "react-helmet"
import Bookmarks from "../components/layout/bookmarks"
import ChannelFeeds from "../components/layout/channel-feeds"
import CompactChannelFeed from "../components/layout/compact-channel-feed"
import DirectMessageFeed from "../components/layout/direct-message-feed"
import DonationFilter from "../components/layout/donation-filter"
import DonationTypeFilter from "../components/layout/donation-type-filter"
import TagFilters from "../components/layout/tag-filters"
import Notice from "../components/widgets/notice"
import { useFilters } from "../hooks/use-filters"
import { useUser, getCachedSecret } from "../hooks/use-user"
import { convertChannels } from "../services/content"
import { useNode } from "../hooks/use-node"
import ResponsiveLayout from "../components/layout/responsive-layout"

const DonatePage: FunctionComponent = () => {
  const { filters } = useFilters()
  const { user, setUser } = useUser()
  const { rpc_impl } = useNode()

  const fetchPage = useCallback(
    async (offset: number) => {
      //console.log("fetchPage")

      let url =
        /*process.env.API_URL +*/ "/api/v2/gateways?count=40&offset=" +
        20 * offset
      if (filters.donation_country != "any")
        url += "&country=" + filters.donation_country
      if (filters.donation_currency != "any")
        url += "&currency=" + filters.donation_currency
      if (filters.donation_processor != "any")
        url += "&processor=" + filters.donation_processor
      if (filters.q != undefined && filters.q != "") url += "&q=" + filters.q
      if (user && user.subscriptions.length) {
        let ids = user.subscriptions.map(ch => ch.id)
        url += "&channel_ids=" + ids.join(",")
      }
      if (filters.tags && filters.tags.length)
        url += "&tags=" + filters.tags.join(",")

      //console.log(url)

      //if (filters.liked == false) {
      const response = await Promise.resolve(
        fetch(url)
          .then(res => res.json())
          .catch(error => {
            console.log("json parsing error occured ", error)
            return null
          })
      )
      if (response?.hits?.hits.length) {
        //console.log(response)
        //let items = convertGateways(response)
        let items = convertChannels(response, filters)
        //for (var i = 0; i < items.length; i++) items[i].amount = -1
        //console.log(items)
        return items
      } else {
        //console.log(response)
        return []
      }
      //} else return []
    },
    [filters]
  )

  const fetchDonationAccount = useCallback(async () => {
    if (user && getCachedSecret() != null) {
      rpc_impl(
        "get_donation_balance",
        user.id,
        user,
        getCachedSecret(),
        function (res) {
          if (res.status == "ok") {
            setUser(
              user =>
                user && {
                  ...user,
                  balance: {
                    ...user.balance,
                    donation_balance: res.data.balance,
                    credit_line: res.data.credit_line,
                  },
                }
            )
          }
        }
      )
    }
  }, [user])

  useEffect(() => {
    fetchDonationAccount()
  }, [])

  return (
    <>
      <Head>
        <title>Smartlike</title>
        <meta name="description" content="Smartlike p2p donations" />
        <meta property="og:title" content="Smartlike" />
        <meta property="og:description" content="Smartlike p2p donations" />
      </Head>

      <ResponsiveLayout>
        {{
          content: (
            <>
              {filters.donation_feed == "Inbox" ? (
                <>
                  <Notice id="donate_inbox_notice" submit="Got it">
                    <p>
                      Incoming donations will be listed below. To receive
                      donations one need to publish a donation address in{" "}
                      <Link to="/settings">Settings</Link> and collect
                      smartlikes from the audience.
                    </p>
                  </Notice>
                  <CompactChannelFeed />
                </>
              ) : filters.donation_feed == "Outbox" ? (
                <>
                  <Notice id="donate_outbox_notice" submit="Hide">
                    <p>
                      Outgoing donations will be listed below for your
                      reference. You can directly contact recipients (
                      <FontAwesomeIcon
                        className=""
                        icon={faQuestionCircle}
                        size="lg"
                      />
                      ) in case you have questions regarding your donation
                      processing.
                    </p>
                  </Notice>
                  <CompactChannelFeed />
                </>
              ) : filters.donation_feed == "Support" ? (
                <>
                  <Notice id="donate_feedback_notice" submit="Hide">
                    <p>
                      Message exchange with your donors and recipients is stored
                      here.
                    </p>
                  </Notice>
                  <DirectMessageFeed />
                </>
              ) : (
                <>
                  <Notice id="donate_notice" submit="Agree">
                    <p style={{ fontSize: "20px" }}>
                      Add funds to your account
                    </p>
                    <p>
                      Smartlike works with <b>decentralized</b> payment gateways
                      to provide the best privacy and security for your payments
                      while taking no fee.
                    </p>
                    <p>
                      Choose your favorite <b>trusted creator</b> or project
                      from the list below and make a{" "}
                      <b>direct regular donation</b> to them. You will receive
                      the same amount on your Smartlike account that you can use
                      for micro-donations to your other favorites.
                    </p>
                    <p>
                      Note that since payments are processed peer-to-peer you
                      would need to address your chosen donation recipient in
                      case a transfer issue should occur. Please read{" "}
                      <a
                        href="https://blog.smartlike.org/terms-of-use"
                        target="_blank"
                      >
                        terms of use
                      </a>{" "}
                      for more details.
                    </p>
                  </Notice>
                  <ChannelFeeds fetchPage={fetchPage} donateFeed={true} />
                </>
              )}
            </>
          ),
          sidebar: (
            <>
              <DonationTypeFilter />
              {filters.donation_feed == "New" && (
                <>
                  <DonationFilter showHeader={"yes"} />
                  <TagFilters />
                  <Bookmarks />
                </>
              )}
            </>
          ),
        }}
      </ResponsiveLayout>
    </>
  )
}
export default DonatePage
