import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from "react"
import { useFilters } from "../../hooks/use-filters"
import { useLazyLoading } from "../../hooks/use-lazy-loading"
import { getCachedSecret, useUser } from "../../hooks/use-user"
import { useNode } from "../../hooks/use-node"
import { DirectMessage } from "../../types"
import DirectMessagePreview from "../direct-message-preview"
import { decrypt } from "../../services/crypto"
import { useIndexedDB } from "../../hooks/use-indexeddb"

type Props = {}

const DirectMessageFeed: FunctionComponent<Props> = ({}) => {
  const [items, setItems] = useState<DirectMessage[]>([])
  const [offsetToken, setOffsetToken] = useState(0)
  const { filters, updateFilters } = useFilters()
  const { user, setUser } = useUser()
  const [decryptMessages, setDecryptMessages] = useState(0)
  const { putKV, get } = useIndexedDB()
  const { rpc } = useNode()

  const fetchPage = useCallback(
    async (offset: number) => {
      //console.log("fetchPage " + offsetToken)
      if (user) {
        /*
        if (!user.dm_keys || user.dm_keys.userid != user.id) {
          let keys = await new Promise(function (resolve) {
            rpc("get_dm_keys", "", user, getCachedSecret(), response => {
              console.log("get_dm_keys " + response)
              if (response.status == "ok" && response.data.length) {
                resolve(JSON.parse(response.data))
              } else resolve(null)
            })
          })
          //console.log("keys " + keys.dm_public_key)
          if (keys) {
            setUser(
              user =>
                user && {
                  ...user,
                  dm_keys: {
                    userid: user.id,
                    dm_public_key: keys.dm_public_key,
                    dm_encrypted_private_key: keys.dm_encrypted_private_key,
                  },
                }
            )
          }
        }*/

        let messages: DirectMessage[] = await new Promise(function (resolve) {
          rpc(
            "get_messages",
            offset.toString(),
            user,
            getCachedSecret(),
            response => {
              //console.log(response)
              let messages: DirectMessage[] = []
              if (response.status == "ok" && response.data.length) {
                for (var i = 0; i < response.data.length; i++) {
                  try {
                    let msg = JSON.parse(response.data[i])
                    let message: DirectMessage = JSON.parse(msg.msg)
                    message.ts = msg.ts
                    message.id = msg.id
                    messages.push(message)
                  } catch (e) {
                    console.log("failed to parse " + response.data[i])
                  }
                }
              }
              resolve(messages)
            }
          )
        })
        console.log(messages)
        return messages
      } else return []
    },
    [user, filters, offsetToken]
  )

  const add = useCallback(
    async (newItems: DirectMessage[], offset: number) => {
      console.log(newItems)
      if (offset == 0) {
        if (items.length > 0) {
          console.log("clearing items")
          setItems([])
        }
        if (newItems.length && user && newItems[0].ts > user.post_read)
          setUser(user => user && { ...user, post_read: newItems[0].ts })
      }
      if (newItems && newItems.length) {
        for (var i = 0; i < newItems.length; i++) {
          const res = await get("messages", newItems[i].id)
          if (res && res.target.result) {
            newItems[i].text = res.target.result
            newItems[i].encrypted = ""
            newItems[i].new = false
          } else {
            newItems[i].encrypted = newItems[i].text
            newItems[i].text = "decrypting..."
            newItems[i].new = true
            spawnDecryption(newItems[i])
          }
        }
        setItems(items => items.concat(newItems))
      }
    },
    [items, user, user?.post_read, decryptMessages]
  )

  const spawnDecryption = useCallback(
    (msg: DirectMessage) => {
      decrypt(
        msg.encrypted,
        user.dm_keys.dm_encrypted_private_key,
        getCachedSecret(),
        user.dm_keys.dm_iv,
        user.id
      ).then(text => {
        updateItem(msg.id, text)
        putKV("messages", msg.id, text)
      })
    },
    [items, user]
  )

  const updateItem = useCallback(
    (id: string, text: string) => {
      setItems(items => {
        const updated = items.map((item, j) => {
          if (item.id == id) {
            item.text = text
          }
          return item
        })
        return updated
      })
    },
    [items]
  )

  const { isLoading, isContentAvailable, resetLoader } =
    useLazyLoading<DirectMessage>(items.length ? items : [], { fetchPage, add })

  useEffect(() => {
    //console.log("Feed: filters changed " + filters.channel_ids)
    resetLoader()
  }, [filters])

  const addMessageToFeed = useCallback(
    (msg: DirectMessage) => {
      console.log("feed: add message ")
      msg.ts = new Date().getTime()
      msg.id = msg.ts
      setItems(items => [msg].concat(items))
    },
    [items]
  )

  const deleteMessageFromFeed = useCallback(
    (msg: DirectMessage) => {
      setItems(items => items.filter(item => item.id !== msg.id))
    },
    [items]
  )

  return (
    <div>
      <ul>
        {items.map(x => (
          <li key={x.ts}>
            <DirectMessagePreview
              msg={x}
              addMessageToFeed={addMessageToFeed}
              deleteMessageFromFeed={deleteMessageFromFeed}
            />
          </li>
        ))}
      </ul>
      <style jsx>
        {`
          ul {
            list-style: none;
            padding: 0;
            margin: 0;
          }
          li:not(:first-child) {
            /*margin-top: 1em;*/
          }
          li {
            margin-bottom: 5px;
          }
          .loader {
            padding: 5em 1em;
            text-align: center;
          }
          span {
            margin-left: 0.5em;
          }
          p {
            margin-top: 100px;
            padding: 20px;
            text-align: center;
          }
        `}
      </style>
    </div>
  )
}

export default DirectMessageFeed
